.cell {
	width: 2.5rem;
	height: 1%;
	font-size: 2rem;
	text-align: center;
	color: var(--grey-color);
	border: 1px solid var(--primary);
	border-radius: 0%;
	padding: 5px;
	outline: none;
	margin: 0;
	caret-color: var(--primary);
	background-color: var(--background-color);
	cursor: default;
	opacity: 1; /* required on iOS */
	-webkit-text-fill-color: var(--grey-color);
}

@media (max-width: 575px) {
	.cell {
		width: 1.5rem;
		font-size: 1.5rem;
	}
}

@media (max-width: 450px) {
	.cell {
		font-size: 1.3rem;
		width: 1.3rem;
	}
}

@media (max-width: 390px) {
	h2 {
		font-size: 1.2rem;
	}
}
@media (max-width: 345px) {
	.cell {
		font-size: 1rem;
		width: 1rem;
	}
}

@media (max-width: 294px) {
	.cell {
		width: 0.8rem;
	}
}

@media (max-width: 244px) {
	.cell {
		width: 0.5rem;
		font-size: 12px;
	}
}

/* .cell:focus, */
.cell-focused {
	outline: none;
	background-color: var(--darker-focus-color) !important;
}

.cell:not([readOnly]):focus,
.cell-focused {
	color: var(--darker-grey-color);
	-webkit-text-fill-color: var(--darker-grey-color);
}

.cell-highlighted {
	background-color: var(--focus-color);
}

.cell-invalid {
	background-color: var(--invalid-color);
	color: var(--grey-color);
	-webkit-text-fill-color: var(--grey-color);
}

.cell-invalid-value {
	background-color: var(--invalid-darker-color);
	color: var(--darker-grey-color);
	-webkit-text-fill-color: var(--darker-grey-color);
}

.cell-invalid-value-cause {
	color: var(--invalid-font-color) !important;
	-webkit-text-fill-color: var(--invalid-font-color) !important;
}

.cell[readOnly] {
	color: var(--font-color);
	-webkit-text-fill-color: var(--font-color);
}

.cell-paused:focus {
	background-color: var(--background-color);
}

/* Sudoku board 3 by 3 border */
.cell:nth-child(3n) {
	border-right: 5px solid var(--primary);
}
.cell[row='3'],
.cell[row='6'] {
	border-top: 5px solid var(--primary);
}
.cell:nth-child(9) {
	border-right: 6px solid var(--primary);
}
.cell[col='0'] {
	border-left: 6px solid var(--primary);
}
.cell[row='0'] {
	border-top: 6px solid var(--primary);
}
.cell[row='8'] {
	border-bottom: 6px solid var(--primary);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
