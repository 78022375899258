.toggle {
	margin: 0px 8px;
	position: relative;
	display: inline-block;
	height: 19px;
	width: 32px;
}

.toggle input {
	display: none;
}

.slider {
	background-color: var(--slider-off);
	position: absolute;
	cursor: pointer;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

.toggle-circle {
	z-index: 3;
	background-color: var(--toggle-circle);
	bottom: 1px;
	content: '';
	height: 13px;
	width: 13px;
	padding: 2px;
	font-size: 10px;
	align-content: center;
	left: 1px;
	position: absolute;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
	cursor: pointer;
}

input:checked + .toggle-circle {
	transform: translateX(13px);
}

input:checked + .toggle-circle + .slider {
	background-color: var(--primary);
}

.slider.round {
	border-radius: 20px;
}

.slider.round:before {
	border-radius: 50%;
}
