.custom-center {
	position: fixed;
	top: 40%;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	color: var(--primary);
	display: block;
}
