.lang-menu-label {
	justify-self: end;
	margin-left: auto;
	margin-right: 1px;
	display: flex;
	align-items: center;
}
.lang-menu-label i {
	margin-right: 4px;
}
