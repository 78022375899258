:root {
	--font-color: #333;
	--background-color: #eee;
	--primary: rgb(91, 133, 136, 1);
	--focus-color: #c8d6d6;
	--darker-focus-color: #8db1b3;
	--invalid-color: #efcece;
	--grey-color: #6d6c6c;
	--darker-grey-color: #4e4e4e;
	--yellow: #c7831d;
	--blue: #3578b2;
	--redish-orange: #e7796b;
	--error: #c24545;
	--purple: #897390;
	--pink: #b95777;
	--white: #ffffff;
	--toggle-circle: #ffffff;
	--slider-off: #d3d3d3;
	--light: #8cb6b8;
	--medium: #4a6d6e;
	--dark: #385253;
	--slider-background: #ccc;
	--light-grey-color: #d3d3d3;
	--lang-color: #3f6467;
	--invalid-font-color: #8e3131;
	--invalid-darker-color: #d28888;
}

[data-theme='dark'] {
	--font-color: rgb(219, 215, 215, 1);
	--background-color: #242727;
	--primary: rgb(91, 133, 136, 1);
	--focus-color: #1b2c2c;
	--darker-focus-color: #0d1d1d;
	--invalid-color: #442424;
	--grey-color: #769091;
	--darker-grey-color: #7d9696;
	--yellow: #8e6528;
	--redish-orange: #91473e;
	--error: #9d3838;
	--pink: #a14f6b;
	--blue: #366e93;
	--white: #dbd7d7;
	--toggle-circle: #2b2b2b;
	--slider-off: #454748;
	--light: #7b9294;
	--medium: #446364;
	--dark: #385253;
	--purple: #746879;
	--slider-background: #ccc;
	--light-grey-color: #adadad;
	--lang-color: #95abad;
	--invalid-font-color: #bb6161;
	--invalid-darker-color: #341313;
}

body {
	background-color: var(--background-color);
	color: var(--font-color);
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: var(--primary);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

select {
	font-weight: bolder;
	font-size: 16px;
}
