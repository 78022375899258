.intructions-bullets {
	padding-left: 13px;
	left: 10rem;
}

.instuctions-clarification,
.intructions-bullets {
	padding-top: 20px;
}

.custom-instructions {
	color: var(--purple);
}
