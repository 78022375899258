.custom-clear {
	color: var(--redish-orange);
}

.custom-new {
	color: var(--blue);
}

.custom-solve {
	color: var(--yellow);
}

.dialog-actions {
	display: flex;
}
