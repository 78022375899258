@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.btn {
	border-radius: 20px;
	cursor: pointer;
	transition: transform 0.3s ease;
	margin: 0.5rem;
	padding: 9px 19px;
	font-size: 17px;
	border: none;
	color: var(--white);
}

@media (hover: hover) and (pointer: fine) {
	.btn:not([disabled]):hover {
		transform: translateY(-3px);
		opacity: 0.8 !important;
	}
}
.btn:focus {
	outline: none;
}

.btn[disabled] {
	pointer-events: none;
}

/* Button colors and styles */
.btn--primary--solid {
	background-color: var(--primary);
}

.btn--yellow--solid {
	background-color: var(--yellow);
}

.btn--redish-orange--solid {
	background-color: var(--redish-orange);
}

.btn--dark--solid {
	background-color: var(--dark);
}

.btn--blue--solid {
	background-color: var(--blue);
}

.btn--purple--solid {
	background-color: var(--purple);
}
.btn--medium--solid {
	background-color: var(--medium);
}
.btn--light--solid {
	background-color: var(--light);
}
.btn--pink-solid {
	background-color: var(--pink);
}
.btn--primary--outline {
	border: 2px solid var(--primary);
	background-color: var(--background-color);
}
